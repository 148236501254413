import React from "react";

interface TokenomicsItemProps {
  title: string;
  value: string;
  icon: string;
  image: string;
}

const TokenomicsItem: React.FC<TokenomicsItemProps> = ({
  title,
  value,
  icon,
  image,
}) => (
  <div className="flex relative gap-5 content-center flex-wrap items-center justify-around px-5 py-9 mt-10 max-w-full rounded-3xl w-[1200px] md:mt-20">
    <div className="flex gap-5 hover-slideDown">
      <div className="flex flex-col self-stretch my-auto text-center whitespace-nowrap">
        <div className="text-5xl font-black tracking-wider leading-9 text-cyan-300">
          {title}
        </div>
        <div className="mt-6 text-4xl font-semibold text-cyan-700">{value}</div>
      </div>
      <img
        loading="lazy"
        src={icon}
        alt=""
        className="shrink-0 self-stretch my-auto border-2 border-solid aspect-[0.83] border-slate-800 fill-cyan-300 stroke-[2px] stroke-slate-800 w-[39px] border-opacity-0"
      />
    </div>
    <div className="flex flex-col justify-center items-start self-stretch px-9">
      <img
        loading="lazy"
        src={image}
        alt={`${title} illustration`}
        className="w-full aspect-[1.35] max-w-[500px] hover-slideDown"
      />
    </div>
  </div>
);

const Tokenomics: React.FC = () => {
  return (
    <section className="flex overflow-hidden relative flex-col justify-center items-center p-20 w-full min-h-[2215px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc41834c8f727d1d532dbebcc1b94b799117246d0be9dd929adcc4b66d0355d0?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
      <h2 className="relative mt-1 text-6xl text-center text-amber-300 uppercase tracking-[5.12px] max-md:max-w-full max-md:text-4xl">
        TOKENOMICS
      </h2>
      <div className="flex relative gap-5 content-center justify-around items-center px-5 py-9 mt-14 max-w-full text-center whitespace-nowrap rounded-3xl w-[1200px] max-md:mt-10 flex-wrap-reverse">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/47354be8a236ffcc0a20551858d2cba5464f280154f20fa9451da0b6df4f0ba2?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
          alt="Tokenomics overview"
          className="self-stretch w-full aspect-[0.92] max-w-[500px] hover-slideDown max-md:max-w-full"
        />
        <div className="flex gap-5 hover-slideDown">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3158a9f02cac7384e9cbb89b6585fd0893b4a3c2451f573a6f7433dcbef09378?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
            alt=""
            className="shrink-0 self-stretch my-auto border-2 border-solid aspect-[0.83] border-slate-800 fill-cyan-300 stroke-[2px] stroke-slate-800 w-[39px] border-opacity-0"
          />
          <div className="flex flex-col self-stretch my-auto">
            <div className="text-5xl font-black tracking-wider leading-9 text-cyan-300">
              Supply
            </div>
            <div className="mt-6 text-4xl font-semibold text-cyan-700">
              10,000,000,000
            </div>
          </div>
        </div>
      </div>
      <TokenomicsItem
        title="Liquidity"
        value="LOCKED"
        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/040a2bb06bc03941dfc7ea436258f72d08680f2bbe497ad75d7aa2a9efa9e5b3?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
        image="https://cdn.builder.io/api/v1/image/assets/TEMP/a90f2b3ac9a9ee33d036f5c7ba49e6c4b054ecee0dd51f792019eb07b660c21a?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
      />
      <TokenomicsItem
        title="Tax"
        value="0%"
        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/8df3e72977b1f1fb9d6fb60132f609e1fa61c9dda97bb8275d1a10db32c67ecf?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
        image="https://cdn.builder.io/api/v1/image/assets/TEMP/0ccbd6c9c28be56da7590ab730ba0f91723a051fde413f0ab36f947fb3112945?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
      />
      <TokenomicsItem
        title="Mint"
        value="DISABLED"
        icon="https://cdn.builder.io/api/v1/image/assets/TEMP/ca25e0c2224f02b4f263f631bdd7c2bcb49902dc78ae1bd7513d2f746cd0933c?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
        image="https://cdn.builder.io/api/v1/image/assets/TEMP/1f55bcd4792bdee09a4cc0d239b8acf0dfbf5580efea2ef90e6f62f65ec05e00?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
      />
      <div className="flex relative flex-col justify-between items-end mt-11 mb-1 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full">
        <div className="text-2xl text-zinc-100">IN PARTNERSHIP WITH</div>
        <div className="flex justify-center items-center p-2.5 mt-1 w-72 max-w-full bg-amber-200 rounded-xl">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d14e34bfb693c451682d8b1af8d6eb6a7f5f78873824bd4cf56e85a4e0b05c5a?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
            alt="Partner logo"
            className="w-full aspect-[4.76]"
          />
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
