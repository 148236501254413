import React from "react";
import "./App.css";
import "./index.css";
import MinionsCoin from "./pages/MinionsCoin";

function App() {
  return <MinionsCoin />;
}

export default App;
