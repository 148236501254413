import React from "react";

const About: React.FC = () => {
  return (
    <section className="flex overflow-hidden relative flex-col flex-wrap justify-center content-center items-center px-16 py-20 w-full min-h-[1070px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/685dcc4f86c8dcc8846ae7d9eaf594d4e4160fccebcc85d6e406c0875e6e615b?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
      <div className="relative my-2.5 w-full max-w-[1222px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex relative flex-col grow py-8 rounded-3xl max-md:max-w-full">
              <h2 className="text-6xl text-amber-300 uppercase tracking-[5.12px] max-md:max-w-full max-md:text-4xl">
                About PapoiCoin
              </h2>
              <p className="mt-6 text-2xl leading-8 text-orange-300 max-md:max-w-full">
                Welcome to{" "}
                <span className="font-black text-orange-300">Papoi Coin</span>{" "}
                – License to Thrill!
                <br />
                <br />
                Step into the world of intrigue and adventure with Papoi Coin,
                where the mischief of Papois meets the suave sophistication of
                a <span className="font-black text-orange-300">007 agent</span>.
                This isn't just any cryptocurrency; it's a top-secret tool for
                those who dare to dive into the exciting blend of finance and
                stealth. Each transaction is a mission, and every holder is part
                of an elite squad of crypto-spies.
                <br />
                <br />
                With{" "}
                <span className="font-black text-orange-300">Papoi Coin</span>,
                you're not just investing; you're embarking on a thrilling quest
                to explore the unknown and uncover riches with the cheekiness of
                a Papoi and the coolness of a spy.
                <br />
                <br />
                Gear up, agent, and join the most{" "}
                <span className="font-black text-orange-300">
                  thrilling crypto operation
                </span>{" "}
                in the world!
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5869c464274b8a0c89f8b4c34a77d29ce1fd976c090654fdea2a6dae87357a9?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
              alt="Papoi Coin Illustration"
              className="self-stretch hover-slideDown my-auto w-full aspect-[1.16] max-md:mt-10 max-md:max-w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
