import React from "react";

interface ButtonProps {
  text: string;
  icon: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ text, icon, onClick }) => (
  <div
    onClick={onClick}
    className="flex flex-1 gap-2 hover-slideDown justify-center px-6 py-4 bg-cyan-300 border-solid shadow-sm border-[3px] border-slate-800 max-md:px-5 w-full md:w-[200px] cursor-pointer"
  >
    <img
      loading="lazy"
      src={icon}
      alt=""
      className="shrink-0 w-6 aspect-square"
    />
    <div>{text}</div>
  </div>
);

const Header: React.FC = () => {
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("Copied!");
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };
  return (
    <header className="flex overflow-hidden relative flex-col justify-center items-center px-10 py-20 w-full min-h-[836px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/44ca609280c70fd3b478e82142bd43e9382e0f471326763f8163fed237463044?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
      <div className="relative mt-20 mb-5 w-full max-w-[1293px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/62b5b20613adb0192b54086948c27e1ed41b5cab818bc7b16511ada401431c28?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
              alt="Papoi Coin Logo"
              className="grow mt-9 w-full aspect-[1.2] hover-slideDown max-md:max-w-full"
            />
          </div>
          <div className="flex flex-col ml-5 w-full max-md:ml-0 max-md:w-full">
            <div className="flex relative flex-col flex-wrap justify-end content-start items-end pl-16 max-md:max-w-full pl-0 ">
              <h1 className="self-start text-8xl w-full text-right text-orange-300 uppercase leading-[114.24px] max-md:max-w-full max-md:text-4xl">
                Papoi Coin
              </h1>
              <h2 className="self-start text-5xl w-full text-right text-white uppercase leading-[50.4px] tracking-[4.2px] max-md:max-w-full">
                License to Thrill
              </h2>
              <div className="flex flex-wrap gap-4 content-start mt-9 text-base font-black leading-6 text-center whitespace-nowrap text-slate-800 w-full md:w-auto">
                <Button
                  text="Dextools"
                  onClick={() =>
                    window.open(
                      "https://www.dextools.io/app/en/base/pair-explorer/0x6E466D0761cEF55D00E729FDF65e0B281AA46175?t=1721276148844",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  icon="https://cdn.builder.io/api/v1/image/assets/TEMP/0bac5c22be85d83cb4244a655ac03075cdbc8d6edb53edd4b8e31aa8066f0f17?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                />
                <Button
                  text="DexScreener"
                  onClick={() =>
                    window.open(
                      "https://dexscreener.com/base/0x6E466D0761cEF55D00E729FDF65e0B281AA46175",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d3b1eccd41c960c138c48f686710def0bc4bd91cb0993f44081bb38f85495e1a?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                />
              </div>
              <div className="flex flex-wrap gap-4 content-start mt-5 text-base font-black leading-6 text-center whitespace-nowrap text-slate-800 w-full md:w-auto">
                <Button
                  text="Telegram"
                  onClick={() =>
                    window.open(
                      "https://t.me/minionsbase",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  icon="https://cdn.builder.io/api/v1/image/assets/TEMP/f806a47357ca479dfe16fdb0348d0940cb12ee34e8c75d600da0e7390bbfbebf?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                />
                <Button
                  text="Twitter"
                  onClick={() =>
                    window.open(
                      "https://x.com/papoi_coin",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  icon="https://cdn.builder.io/api/v1/image/assets/TEMP/dd5bd7bf00cd00d911ee47f7008c285863e33ca7359a1aa85d67101a1fde6119?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                />
              </div>
              <div
                className="flex flex-wrap gap-4 content-center mt-8"
                style={{ maxWidth: 414, width: "100%" }}
              >
                <div className="flex-1 justify-center px-6 py-2 text-lg font-bold bg-zinc-50 text-ellipsis leading-[32px] text-neutral-500 max-md:px-5 w-full max-w-[306px] overflow-hidden">
                0xAA745ac4e44968F43315aeb8dF4ab5a28073469A
                </div>
                <button
                  onClick={() =>
                    copyToClipboard(
                      "0xAA745ac4e44968F43315aeb8dF4ab5a28073469A"
                    )
                  }
                  className="justify-center self-start px-6 py-2.5 text-base font-semibold leading-6 text-center whitespace-nowrap border-2 border-cyan-300 border-solid shadow-sm text-zinc-50 max-md:px-5"
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
