import React from 'react';
import Header from './Header';
import About from './About';
import Tokenomics from './Tokenomics';
import HowToBuy from './HowToBuy';
import Footer from './Footer';


const MinionsCoin: React.FC = () => {
  return (
    <div className="flex flex-col justify-center">
      <Header />
      <About />
      <Tokenomics />
      <HowToBuy />
      <Footer />
    </div>
  );
};

export default MinionsCoin;