import React from 'react';

interface StepProps {
  title: string;
  description: string;
}

const Step: React.FC<StepProps> = ({ title, description }) => (
  <div className="flex flex-col hover-slideDown px-6 py-8 mt-8 rounded-2xl shadow-sm bg-yellow-950 max-md:px-5 max-md:max-w-full">
    <h3 className="text-3xl font-black tracking-wider leading-7 uppercase text-ellipsis text-zinc-50 max-md:max-w-full">
      {title}
    </h3>
    <p className="mt-6 text-2xl tracking-tighter leading-8 text-gray-200 max-md:max-w-full">
      {description}
    </p>
  </div>
);

const HowToBuy: React.FC = () => {
  const steps = [
    {
      title: "Add Base to MetaMask",
      description: "Download & install the Metamask Wallet either from the app store on your phone of the brower extension for desktop"
    },
    {
      title: "Load Up ETH",
      description: "Head over to bridge.base.org, connect your wallet on the Ethereum blockchain. Choose how much ETH you would like to bridge from the ERC20 network to Base. Remember to leave enough for gas Fees!"
    },
    {
      title: "Buy",
      description: "Head over to Uniswap and paste the PAP contract address listed on this website to swap your ETH"
    },
    {
      title: "Add to your wallet",
      description: "Now all you have to do is add the PAP contract address to your Metamask Wallet for your PAP tokens to show. Welcome aboard!"
    }
  ];

  return (
    <section className="flex overflow-hidden relative flex-col flex-wrap justify-center content-center items-center px-20 pt-9 pb-20 w-full min-h-[1426px] max-md:px-5 max-md:max-w-full">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/db7b615fe736f4396548203fe49634eff6fd88acc9face1a27e35898f19f9da9?apiKey=f9f711ac72684fe79caf31b99cec2d64&" alt="" className="object-cover absolute inset-0 size-full" />
      <h2 className="relative text-5xl text-amber-300 uppercase tracking-[3.84px] max-md:max-w-full max-md:text-4xl">
        How to Buy
      </h2>
      <div className="relative flex-wrap gap-y-12 justify-between content-center mt-16 mb-1.5 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex relative flex-col justify-end self-stretch pt-14 my-auto max-md:mt-10 max-md:max-w-full">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/60db459f20473af18b665a03aba48b8f2fea4aa0404ce0fa8f09c8af37e6ecc6?apiKey=f9f711ac72684fe79caf31b99cec2d64&" alt="How to buy illustration" className="w-full hover-slideDown aspect-[0.55] max-md:max-w-full" />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex relative flex-col flex-wrap grow justify-center content-start px-2.5 max-md:max-w-full">
              {steps.map((step, index) => (
                <Step key={index} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToBuy;