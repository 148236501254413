import React from "react";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="flex overflow-hidden relative flex-col flex-wrap justify-center content-center items-center px-20 py-16 w-full text-center min-h-[1055px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/87c8bc0d0574f67fedd83c0adead3a66de8eb2ea57506f5d385fa0b2c152e8c2?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
          alt=""
          className="object-cover absolute inset-0 size-full"
        />
        <h2 className="relative self-stretch mt-12 text-6xl text-amber-300 uppercase leading-[84px] tracking-[4.8px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-[62px]">
          Papoi Coin <br /> License to Thrill!
        </h2>
        <button
          onClick={() =>
            window.open(
              "https://app.uniswap.org/swap?outputcurrency=0xAA745ac4e44968F43315aeb8dF4ab5a28073469A&chain=base&utm_source=dexscreener&utm_medium=app",
              "_blank",
              "noopener,noreferrer"
            )
          }
          className="relative justify-center px-6 py-4 mt-8 text-base leading-6 bg-cyan-300 border-solid shadow-sm border-[3px] border-slate-800 text-slate-800 max-md:px-5 rounded-lg"
        >
          Buy Now
        </button>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/44d26e4c2fb7885c40d6c2be9ce619334869109e06ffe32f4819076d61fa8685?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
          alt="Papoi Coin characters"
          className="mt-10 mb-6 max-w-full aspect-[1.39] w-[743px] z-10 hover-slideDown"
        />
      </div>
      <div className="flex justify-center items-center px-16 py-4 w-full text-lg font-black tracking-widest text-center bg-amber-300 text-orange-950 max-md:px-5 max-md:max-w-full">
        <div className="flex gap-2 max-md:flex-wrap">
          <div className="max-md:max-w-full">
            © 2024 PAPOI COIN | All Rights Reserved
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9d3c1e5a9ff73f07e66e974d1986ed33c6aa2a111afaf6ad3eaf276e51b8d31f?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
            alt=""
            className="shrink-0 my-auto aspect-square fill-orange-950 w-[9px] max-md:hidden"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
